.accountNav:has(.accountNav__list--visible) {
    .accountNav__dropdown--svgDown {
        @apply hidden;
    }

    .accountNav__dropdown--svgUp {
        @apply block w-4 h-4;
    }
    
    .accountNav__list--visible {
        @apply flex;
    }
}

.accountNav {
    @apply relative;
    
    .accountNav__button {
        @apply lg:hidden flex items-center w-full justify-between gap-x-1.5 px-4 py-2 ring-1 ring-inset ring-amcBlack h-12;
    }
    
    .accountNav__dropdown--svgDown {
        @apply block w-4 h-4;
    }
    
    .accountNav__dropdown--svgUp {
        @apply hidden;
    }
    
    .accountNav__item {
        @apply flex;
    }
    
    & .accountNav__list {
        @apply max-lg:hidden flex flex-col absolute lg:relative right-0 w-full bg-amcWhite ring-1 ring-amcBlack ring-opacity-5 border-y border-amcLightGrey max-lg:z-10;
    
        .accountNav__item {
            @apply text-amcBlack hover:bg-amcOffWhite border-b border-amcLightGrey py-3 pl-3 flex items-center;
            
            &.accountNav__item--isActive {
                @apply font-semibold bg-amcOffWhite;
            }
        }
        
        .accountNav__svg {
            @apply hidden lg:block w-6 h-6 mr-5;
        }
    }
}

.account__block-text {
    @apply lg:w-1/2 px-4 lg:pr-8 mb-12;
}

.account__title {
    @apply w-full lg:w-4/5 font-gin mb-4 mt-2 py-4 lg:pt-5 lg:pb-2 text-2xl mx-auto border-y-2 border-amcLightGrey text-center lg:border-none;
}

.account__summary-title {
    @apply mb-4 border-b-2 border-amcBlack font-semibold text-xl;
}

.account__link {
    @apply underline hover:text-amcPrimaryBlue-hover hover:no-underline;
}

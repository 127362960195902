.category-description {
    @apply space-y-4;

    h2,
    h3,
    h4,
    h5 {
        @apply font-gin text-xl;
    }

    a {
        @apply underline text-amcPrimaryBlue;

        &:hover {
            @apply no-underline text-amcPrimaryBlue-hover;
        }
    }

    .video-wrapper {
        iframe {
            @apply w-full h-full aspect-video;
        }
    }
}

.contentBanners {
    @apply p-4 text-center border;

    &.blue {
        @apply border-amcPrimaryBlue bg-amcPrimaryBlue text-amcWhite;
    }

    &.grey {
        @apply border-amcLightGrey bg-amcOffWhite;
    }
}

.form__label {
    @apply font-semibold text-amcBlack flex justify-between items-center;
}

.form__input {
    @apply border border-amcBlack p-4 md:p-5 w-full placeholder-amcBlack rounded-none;
}

.form__label--small {
    @apply font-normal text-amcBlack uppercase text-right float-right text-xs;
}

.form__select {
    @apply border border-amcBlack p-4 md:p-5 placeholder-amcBlack rounded-none w-full text-amcBlack appearance-none;
}

.form__checkbox {
    @apply size-5 border border-amcLightGrey group-hover:border-amcDarkGrey;
}

.form__checkbox[type="checkbox"]:checked {
    @apply border-amcDarkGrey accent-amcDarkGrey;
}

.form-field {
    @apply col-span-3;
}

.form-field[data-type="EmailAddress"] {
    @apply col-span-6;
}

.form-field--select:has([data-label="Title"]) {
    @apply col-span-1;
}

.form-field[data-type="FirstName"] {
    @apply col-start-2 col-end-4;
}

.form__errors {
    @apply bg-white text-amcHighlightRed mb-5;
}

.form__success {
    @apply bg-white text-amcTeal my-5 !important;
}

.form-field--input {
    @apply relative;
}

.form-label {
    @apply text-amcBlack;
}

.form-checkbox + .form-label::before {
    @apply rounded-none border-amcBlack;
}

#consent-manager {
    @apply right-2 left-2 bottom-2 sm:right-4 sm:left-4 sm:bottom-4 !important;

    .css-10z1g2q {
        @apply bg-amcWhite border border-amcLightGrey tracking-normal font-gibson text-base font-normal text-amcBlack w-full p-4 shadow-xl shadow-amcDarkGrey/50 !important;

        > div {
            flex-flow: inherit !important;

            @apply mt-0 gap-4 items-center flex-col lg:flex-row !important;
        }
    }

    .css-1wn42nw {
        @apply w-full grid-cols-2 grid items-center gap-4 sm:grid-cols-3 lg:place-content-end lg:grid-cols-[repeat(3,190px)];
    }

    .css-1k76mzs,
    .css-a0j149 {
        @apply flex items-center text-base justify-center p-4 py-3 ml-0 h-auto rounded-none w-full uppercase bg-amcWhite text-amcBlack border border-amcLightGrey hover:text-amcBlack hover:bg-amcOffWhite focus:text-amcBlack !important;
    }

    .css-a0j149:nth-child(3) {
        @apply col-span-2 bg-amcPrimaryBlue border-amcPrimaryBlue font-medium text-amcWhite hover:bg-amcPrimaryBlue-hover hover:text-amcWhite focus:text-amcWhite sm:col-span-1 !important;
    }

    .css-ra9y5f {
        @apply mt-0 text-amcBlack !important;
    }

    .css-1wn42nw {
        @apply mt-0 !important;
    }

    .css-bhz559 {
        @apply text-base !important;
    }
}

#consent-manager-update-banner {
    @apply hidden;
}

.css-ueuzok {
    @apply bg-amcBlack bg-opacity-70 !important;
}

.css-zikcg4,
.css-yxm2n2 {
    @apply rounded-none tracking-normal font-gibson text-base font-normal text-amcBlack !important;
}

.css-1353ifz {
    @apply p-4 !important;
}

.css-18uvdg2 {
    @apply mb-0 !important;
}

.css-et0a3j {
    @apply p-0 !important;
}

.css-1er9f2w {
    @apply text-amcBlack font-gin text-2xl font-medium !important;
}

.css-1s4cc41 {
    @apply text-base pt-0 !important;
}

.css-1xa4cku {
    @apply mt-0 pt-4 !important;
}

.css-1zvabp,
.css-3e0hge {
    border: 1px solid !important;

    @apply items-center h-auto text-base rounded-none justify-center p-4 py-3 uppercase bg-amcWhite text-amcBlack border-amcLightGrey hover:text-amcBlack hover:bg-amcOffWhite focus:text-amcBlack !important;
}

.css-3e0hge {
    @apply bg-amcPrimaryBlue font-medium ml-4 border-amcPrimaryBlue text-amcWhite hover:bg-amcPrimaryBlue-hover hover:text-amcWhite focus:text-amcWhite !important;
}

.css-7xc4dj {
    @apply text-base !important;
}

.css-1va7l79 th,
.css-1va7l79 td {
    border: 1px solid !important;

    @apply text-amcBlack border-amcLightGrey !important;
}

.css-1umj05o {
    @apply bg-amcOffWhite !important;
}

.css-rits9q {
    @apply p-4 !important;
}

.shpmt-title {
    @apply hidden;
}

.shpmt-items {
    @apply hidden;
}

.shpmt-cont {
    @apply xl:w-2/3;
}

.shpmt-options {
    @apply w-full !important;
}

.shp-option {
    @apply relative;
}

.opt-selection {
    @apply relative !important;
}

.opt-selection:hover {
    @apply cursor-pointer;
}

div.opt-selection:has(.is-checked) .o-estimate {
    @apply invisible;
}

.opt-dt {
    @apply absolute top-0 right-0 bg-white text-black p-0 m-0 w-auto !important;
}

.opt-dt-tm {
    @apply md:bg-white right-0 top-0;
}

.o-dt-entry {
    @apply text-end font-semibold text-sm underline;
}

.o-dt-entry:hover {
    @apply text-amcPrimaryBlue !important;
}

.o-edit--date {
    @apply hidden !important;
}

.shq-monthly-cal {
    @apply xl:absolute xl:-top-24 xl:left-full xl:ml-6 xl:z-10 border-4 
        border-amcPrimaryBlue rounded-none !important; 
}

.shq-monthly-cal:before {
    @apply hidden content-[''] xl:block xl:absolute w-0 h-0 top-[93px] -left-5;
    /* below is used to create the triangle that will sit to the side of the calendar */
    @apply border-solid border-x-[10px] border-b-[18px] border-t-transparent border-r-transparent
        border-b-amcPrimaryBlue border-l-transparent rotate-[270deg];
}

.shq-weekdays-header {
    @apply max-w-md px-4 pt-2 !important;
}

.shq-month-days {
    @apply max-w-md px-4 !important;
}

.shp-option > div:nth-child(2){
    @apply flex justify-center !important;
}

.shq-month-nav {
    @apply rounded-none !important;
}

.o-method-estimate {
    @apply flex justify-between !important;
}

.o-estimate {
    @apply flex w-7/12 justify-end mt-0 !important;
}

.shp-toggle > .btn-group > .active {
    @apply bg-amcPrimaryBlue !important; 
}

.shp-toggle > .btn-group > .btn {
    @apply first:rounded-l-xl last:rounded-r-xl !important;
}

.shq-day-tooltip {
    @apply left-1/2 -translate-x-1/2 -translate-y-2 !important;
}

.shq-day-tooltip::after {
    @apply left-1/2 top-auto -bottom-2 -translate-x-1/2 rotate-45 !important;
}

.productCard {
    .productCard__image {
        @apply w-full aspect-square relative;
    }

    .productCard__image__img {
        @apply relative w-full h-full object-contain;
    }

    .productCard_promo_flag_img {
        @apply absolute top-0 left-0 w-16 h-auto;
    }

    .productCard_promo_icon_img {
        @apply absolute top-1/2 -translate-y-1/2 right-0 w-12 h-auto;
    }

    .productCard__details {
        @apply mt-4 divide-y divide-amcOffWhite border-b border-amcOffWhite;
    }

    .productCard__title {
        @apply pb-2 leading-5 !border-t-0;
    }

    .productCard__reviews {
        @apply flex justify-between;

        .productCard__rating-background {
            @apply relative fill-amcLightGrey flex items-center gap-0.5;

            svg {
                @apply size-4;
            }

            .productCard__rating-front {
                @apply fill-amcReviewsYellow absolute top-0 left-0 overflow-hidden h-full;

                div {
                    @apply absolute flex items-center top-0 left-0 gap-0.5 h-full;
                }
            }
        }
    }

    .productCard__priceContainer {
        @apply space-x-2 py-1;
    }

    .productCard__price {
        @apply font-semibold;
    }

    .productCard__priceSale {
        @apply text-amcOffersRed line-through;
    }

    .productCard__promoText {
        @apply bg-amcPrimaryBlue p-2 text-amcWhite;
    }

    .productCard__callToAction {
        @apply btn btn--green btn--small mt-2;
    }
}

.s48meganav {
    @apply max-lg:hidden;

    .menu-block__depth-0--item:last-child {
        @apply bg-amcOffersRed text-white;
    }
}

@screen lg {
    .s48meganav-layout,
    .s48meganav-sub-layout {
        @apply z-20 !important;
    }

    .s48meganav {
        @apply bg-black w-full !important;
    
        .nav {
            @apply max-w-screen-xl px-2 !important;
    
            .menu-block--wrapper {
                @apply bg-transparent !important;
            }


            /**
             ** Menu Depth: 0
             **/
            
            .menu-block__depth-0 {
                @apply justify-between !important;
            }
    
            .menu-block__depth-0--item {
                @apply hover:bg-amcPrimaryBlue;
    
                &:hover > .menu-block--anchor:not(.menu-block--no-link) {
                    @apply text-amcWhite !important;
                }
    
                & > .menu-block--anchor {
                    @apply font-gibson text-center px-3 py-1.5 text-amcWhite !important;
                    
                    .menu_block__depth-0--heading {
                        @apply font-medium !important;
                    }
                }
            }


            /**
             ** Menu Depth: 1
             **/
    
            .menu-block__depth-1--wrapper {
                @apply bg-amcPrimaryBlue py-6 px-2 !important;
            }
            
            .menu-block__depth-1 {
                @apply w-full max-w-screen-xl text-amcWhite p-0 gap-4 !important;
            }
            
            .menu-block__depth-1--item {
                @apply m-0 !important;
                
                & > .menu-block--anchor {
                    @apply hidden !important;
                }
            }


            /**
             ** Menu Depth: 2
             **/

             .menu-block__depth-2--item {
                @apply m-0 mb-8 p-0 !important;
                
                & > .menu-block--anchor {
                    @apply text-amcWhite !important;
                }
                
                &:hover > .menu-block--anchor:not(.menu-block--no-link) {
                    @apply text-amcWhite !important;
                }
            }
                        
            .menu_block__depth-2--heading {
                @apply text-lg leading-6 pb-2 mb-2 border-b border-amcWhite font-semibold !important;
            }
             

            /**
             ** Menu Depth: 3
             **/

            .menu-block__depth-3--wrapper {
                @apply block !important;
            }
            
            .menu-block__depth-3--item {
                @apply p-0 !important;
            }
          
            .menu_block__depth-3--heading {
                @apply text-base hover:underline font-thin;
            }
        }
    }    
}

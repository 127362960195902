@import "lib/tailwind/base.css";
@import "lib/cornerstone/loading-spinner.css";
@import "lib/swiper.css";

@import "components/common/button.css";
@import "components/common/modal.css";
@import "components/common/stock-status.css";

@import "components/addToWishListModal.css";
@import "components/cookie.css";
@import "components/forms.css";
@import "components/meganav.css";
@import "components/mobilenav.css";
@import "components/productCard.css";

@import "layouts/checkout/checkout.css";
@import "layouts/checkout/order-confirmation.css";

@import "theme/account.css";
@import "theme/cart.css";
@import "theme/category.css";
@import "theme/product.css";

@import "utils/prose.css";
@import "utils/shogun.css";

@layer base {
    html {
        @apply text-amcBlack;
    }

    body {
        @apply font-sans !important;
        -moz-osx-font-smoothing: auto !important;
        -webkit-font-smoothing: auto !important;
    }

    summary::-webkit-details-marker {
        display: none;
    }
}

.addToWishListModal {
    @apply w-full max-w-xl;

    .addToWishListModal__wrapper {
        @apply max-w-sm mx-auto;
    }

    .addToWishListModal__body {
        @apply space-y-4;

        form {
            @apply flex flex-col;

            label {
                @apply font-semibold;
            }
            .addToWishListModal__body__select {
                @apply relative;

                select {
                    @apply appearance-none w-full border border-amcBlack p-4 mt-3 mb-4;
                }
                svg {
                    @apply absolute top-1/2 -translate-y-1/2 right-0 pointer-events-none w-4 h-4 mr-5;
                }
            }
        }
    }
}

.swiper-button-next {
    @apply z-10 flex size-11 bg-amcLightGrey bg-opacity-75 right-0 !important;
}

.swiper-button-prev {
    @apply z-10 flex size-11 bg-amcLightGrey bg-opacity-75 left-0 !important;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    @apply text-base text-amcBlack !important;
}

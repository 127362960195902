.shogun-root {
    .shg-fw {
        @apply w-full mx-auto !important;
    }

    .shg-category-grid,
    .shg-category-row,
    .shg-category-row-hor {
        @apply m-0 !important;
    }
}

.feefo-review-badge-wrapper-product {
    @apply flex mt-4;
    .summary-rating {
        .feefo-rating-stars {
            @apply flex;
        }
        .reviews-count {
            @apply text-amcBlack;
        }
    }
}

.feefo-review-widget-product svg {
    @apply inline;
}

@import "cart-drawer.css";
@import "order-summary.css";
@import "customer.css";
@import "payment.css";
@import "shipping.css";

html {
    font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @apply font-gibson text-base font-normal;
}

.icons-svg-sprite {
    @apply hidden;
}

.modalOverlay {
    @apply bg-amcBlack bg-opacity-90;
}

#checkout-app {
    @apply font-gibson text-base font-normal text-amcBlack max-lg:pb-24 !important;

    a:not(#orderConfirmation--details a) {
        @apply font-gibson text-base font-normal underline text-inherit hover:no-underline hover:text-amcPrimaryBlue-hover !important;
    }

    .layout.optimizedCheckout-contentPrimary {
        @apply w-full max-w-screen-xl my-8 mx-auto px-4 pb-0 !important;
    }

    .layout-main {
        @apply pr-0 pl-0 !important;
    }

    .layout-cart {
        @apply pl-8 pr-0 !important;
    }

    .checkout-separator::before,
    .checkout-separator::after {
        @apply bg-amcBlack !important;
    }

    .checkout-steps {
        @apply pr-0 mt-2 !important;
    }

    .checkout-step {
        @apply border-0 p-0 !important;
    }

    .checkout-view-header {
        @apply mb-4 !important;
    }

    .checkout-view-content {
        @apply my-4 pb-8 pt-4 border-b border-amcBlack !important;
    }

    .stepHeader,
    .optimizedCheckout-headingSecondary {
        @apply border-b border-amcBlack p-0 pb-4 mb-4 !important;
    }

    .stepHeader {
        pointer-events: none;
    }

    .optimizedCheckout-headingSecondary {
        @apply text-amcBlack w-full !important;

        border: inherit;
    }

    .stepHeader-title,
    .stepHeader-body,
    .stepHeader-actions {
        @apply flex items-center !important;
    }

    .stepHeader-body,
    .stepHeader-actions {
        @apply mt-0 !important;
    }

    .stepHeader-title,
    .optimizedCheckout-headingSecondary {
        @apply font-gibson text-xl font-medium !important;
    }

    .checkout-step--shipping .form-legend-container,
    .checkout-step--billing .form-legend-container {
        @apply sr-only !important;
    }

    .checkout-step--customer .form-legend-container {
        @apply mb-4 !important;
    }

    .form-fieldset {
        @apply mb-0 !important;
    }

    .form-field .floating-input,
    .form-field .floating-select {
        @apply text-base !important;
    }

    .form-field input {
        @apply outline-none w-full border border-amcDarkGrey rounded-none !important;
    }

    .form-field .floating-label,
    .form-field .floating-label small {
        @apply text-amcBlack font-normal text-base !important;

        line-height: 3.5 !important;
    }

    .form-field .react-datepicker__tab-loop + .floating-label,
    .form-field .react-datepicker-wrapper + .floating-label,
    .form-field select + .floating-label,
    .form-field textarea:focus + .floating-label,
    .form-field textarea:not(:empty) + .floating-label,
    .form-field input:focus + .floating-label,
    .form-field input:not(:placeholder-shown) + .floating-label,
    .form-field .react-datepicker__tab-loop + .floating-label small,
    .form-field .react-datepicker-wrapper + .floating-label small,
    .form-field select + .floating-label small,
    .form-field textarea:focus + .floating-label small,
    .form-field textarea:not(:empty) + .floating-label small,
    .form-field input:focus + .floating-label small,
    .form-field input:not(:placeholder-shown) + .floating-label small {
        line-height: 2 !important;
        font-size: 0.8461538462rem !important;
    }

    .form-field select {
        @apply outline-none w-full cursor-pointer appearance-none border border-amcDarkGrey rounded-none !important;
    }

    .optimizedCheckout-buttonPrimary,
    .optimizedCheckout-buttonSecondary {
        @apply font-gibson text-base font-normal text-amcBlack disabled:opacity-75 disabled:cursor-not-allowed !important;
    }

    .optimizedCheckout-buttonPrimary {
        @apply bg-amcBlack font-semibold text-amcWhite rounded-none hover:bg-amcDarkGrey hover:text-amcWhite !important;
    }

    .optimizedCheckout-buttonSecondary {
        @apply bg-amcWhite text-amcBlack uppercase rounded-none no-underline hover:text-amcBlack hover:bg-amcOffWhite !important;
        pointer-events: auto;
    }

    .create-account-form {
        @apply mb-4 !important;
    }

    #checkout-payment-continue, #amc-checkout-payment-continue {
        &:not(:has(.paypal-buttons)) {
            @apply border-amcFinanceGreen bg-amcFinanceGreen font-gin text-2xl uppercase text-amcWhite hover:bg-amcFinanceGreen-hover disabled:bg-amcFinanceGreen hover:text-amcWhite !important;
        }

        &:not([data-disabled="false"]) {
            @apply pointer-events-none opacity-75 cursor-not-allowed;
        }
    }

    #checkout-shipping-options {
        @apply mt-8 !important;
    }

    #checkout-shipping-options .shippingOptions-panel {
        @apply mt-4 mb-8 border-amcBlack rounded-none !important;
    }

    .shippingOptions-container {
        @apply mt-4 mb-8 !important;
    }

    .shippingOption--selected {
        @apply border-amcLightGrey !important;
    }

    #checkoutShippingAddress {
        @apply mb-4 !important;
    }

    .shippingOption-price {
        @apply font-normal !important;
    }

    [data-test="checkout-shipping-comments"] .form-body {
        @apply mt-4 !important;
    }

    [data-test="checkout-shipping-comments"] {
        @apply mb-8 !important;
    }

    #checkoutBillingAddress {
        @apply mb-4 !important;
    }

    .checkout-step--payment .checkout-view-header {
        @apply mb-0 !important;
    }

    .checkout-step--payment .checkout-view-content {
        @apply border-b-0 mb-0 pb-0 border-t pt-8 !important;
    }

    .checkout-step--payment .form-actions {
        @apply mt-8 !important;
    }

    .checkout-step--payment .stepHeader {
        @apply border-b-0 pb-0 mb-0 !important;
    }

    .optimizedCheckout-orderSummary .optimizedCheckout-headingSecondary {
        @apply w-auto border-none pb-0 !important;
    }
}

.cart.optimizedCheckout-orderSummary {
    @apply my-0 shadow-none border-none rounded-none !important;
}

.optimizedCheckout-orderSummary [data-test="cart-taxes"] .cart-priceItem {
    @apply mb-0 !important;
}

.optimizedCheckout-orderSummary .product .product-figure {
    @apply size-20 relative p-0 border border-amcLightGrey mr-4 !important;
}

.optimizedCheckout-orderSummary .product .product-figure img {
    @apply absolute top-1/2 left-1/2 max-h-full object-contain transform -translate-x-1/2 -translate-y-1/2 !important;
}

.optimizedCheckout-orderSummary
    .optimizedCheckout-orderSummary-cartSection {
    @apply border-amcLightGrey !important;
}

.optimizedCheckout-orderSummary [data-test="cart-count-total"] {
    @apply sr-only !important;
}

.redeemable-label,
.optimizedCheckout-orderSummary-cartSection:has(
        [data-test="tax-text"] + [data-test="cart-taxes"]
    ),
.optimizedCheckout-orderSummary [data-test="cart-taxes"] {
    display: none;
}

.optimizedCheckout-form-checklist {
    @apply border-black !important;
}

.form-checklist:only-child,
.checklist-skeleton:only-child {
    @apply rounded-none text-amcBlack !important;
}

.form-checklist-header .optimizedCheckout-form-label {
    @apply font-semibold text-lg p-6 pl-20 !important;
}

.form-checklist-header.form-checklist-header--selected
    .optimizedCheckout-form-label {
    @apply cursor-default !important;
}

.form-checklist-body {
    @apply m-0 !important;

    .paymentMethod {
        @apply mx-6 mb-6 !important;

        .optimizedCheckout-form-input,
        .optimizedCheckout-form-select {
            @apply border-amcBlack rounded-none !important;
        }

        .form-field--saveInstrument {
            @apply pt-4 !important;
        }
    }

    .optimizedCheckout-form-label {
        @apply text-amcBlack text-base font-normal cursor-auto !important;
    }

    .form-ccFields {
        @apply m-0 gap-4 sm:gap-6 !important;
    }

    .form-ccFields > .form-field,
    .form-ccFields > .form-ccFields-field {
        @apply m-0 p-0 !important;
    }

    .form-field--error .optimizedCheckout-form-input,
    .form-field--error .optimizedCheckout-form-select {
        @apply rounded-none !important;
    }
}

.optimizedCheckout-form-checklist-item {
    @apply border-black !important;
}

.optimizedCheckout-form-checklist-item:hover,
.optimizedCheckout-form-checklist-item--selected {
    @apply border-black bg-amcOffWhite !important;
}

.paymentMethod--creditCard,
.paymentMethod--hosted,
.paymentMethod--walletButton {
    @apply p-0 !important;
}

.creditCardTypes-list {
    @apply hidden sm:flex gap-2 sm:max-w-none !important;

    .creditCardTypes-list-item {
        @apply w-auto m-0 !important;
    }

    .cardIcon-icon {
        @apply w-8 h-8 !important;
    }
}

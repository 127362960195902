/* Express Payment Buttons Override */
[data-cart]:has([data-zero-cart-total]) {
    .cart-additionalCheckoutButtons {
        @apply hidden;
    }
}

[data-content-region="cart_below_totals"] {
    @apply overflow-auto;

    #banner-region- {
        @apply mb-0;
    }
}

#banner-region-:has(#paypal-commerce-cart-messaging-banner) {
    @apply w-full sm:max-w-md float-left sm:float-right;
}

.cart-additionalCheckoutButtons {
    @apply space-y-4;

    .or-use-label {
        @apply hidden !important;
    }

    .ApplePayContainer,
    .PaypalCommerceContainer,
    .PaypalCommerceCreditContainer,
    .GooglePayContainer {
        @apply w-full sm:max-w-md;
    }

    .ApplePayInnerContainer,
    .GooglePayInnerContainer,
    .PaypalCommerceInnerContainer,
    .PaypalCommerceCreditInnerContainer {
        @apply mb-0 w-full max-w-full;
    }

    .PaypalCommerceInnerContainer,
    .PaypalCommerceCreditInnerContainer {
        @apply block;

        .paypal-buttons {
            @apply block;
        }
    }
}

#paypal-commerce-cart-messaging-banner {
    @apply mt-4;
}

.form-checklist-item.amc-finance-disabled {
    @apply bg-amcLightGrey pointer-events-none;

    label::before, label::after {
        @apply bg-amcLightGrey-hover;
    }
    .paymentProviderHeader-name::after {
        @apply ml-1;
        content: "(Not Available - Shipping and billing address must match)";
    }
}

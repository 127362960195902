.modal-background {
    @apply bg-amcBlack bg-opacity-90 opacity-100 transition-none max-h-none;
}

.modal {
    @apply !top-1/2 !left-1/2 bg-transparent p-4 rounded-none shadow-none !-translate-x-1/2 !-translate-y-1/2 m-0 max-h-none;

    &.open {
        @apply block visible opacity-100;
    }

    .modal__content {
        @apply relative bg-amcWhite p-10 max-h-[80vh] overflow-auto;

        .modal__content__heading {
            @apply font-gin uppercase text-2xl text-center mb-8;
        }
    }
}

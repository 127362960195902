/*
    This CSS hides level 1 from the mobile menu. This is required since level 1
    is actually a secret "column" layer, used for layout purposes on the desktop
    mega nav.

    It works by redefining the OOTB styles for each scroll position, effectively
    pushing each scroll position along by one.

    In short, this means that:
        - Menu level 2 becomes level 1
        - Menu level 3 becomes level 2
        - Menu level 4 becomes level 3
*/

@media not all and (min-width: theme('screens.lg')) {
    /* Basic Styles */
    .s48meganav {
        @apply top-0 h-full max-w-72 pt-0 bg-amcDarkGrey text-amcWhite border-none !important;

        nav {
            @apply flex flex-col !important;
        }

        &::before {
            content: '';
            @apply fixed inset-0 left-72 bg-amcBlack bg-opacity-80;
        }

        .menu-back-btn {
            @apply static bg-amcBlack text-amcWhite border-b border-amcWhite !important;

            &:not(.menu-back-btn--active) {
                @apply hidden;
            }
        }

        .mega-menu-close {
            @apply z-10 size-10 bg-amcWhite fixed top-0 left-72 flex justify-center items-center !important;

            & svg {
                @apply size-3;
            }
        }

        .menu-breadcrumb--active {
            @apply font-gibson bg-amcPrimaryBlue m-0 pl-3 py-1.5 font-semibold !important;
        }

        .menu_block--heading {
            @apply text-lg font-gibson text-amcWhite px-3 py-1.5 font-medium !important;

            &::after {
                @apply top-2 right-5 mr-0 text-amcWhite !important;
            }
        }

        /* Dark Level 1 Menu */
        .menu-block__depth-0 {
            @apply divide-y divide-amcBlack;

            .menu-block--wrapper {
                @apply bg-amcPrimaryBlue !important;
            }

            .menu-block--item .menu-block--anchor .menu_block--heading::after {
                @apply text-amcWhite !important;
            }
        }

        /* Light Level 2 and above submenus */
        .menu-block__depth-1 li,
        .menu-block__depth-2,
        .menu-block__depth-3 {
            @apply divide-y divide-amcWhite;

            .menu-block--wrapper {
                @apply bg-amcPrimaryBlue !important;
            }

            .menu-block--item {
                @apply bg-amcPrimaryBlue;
            }

            .menu-block--item .menu-block--anchor .menu_block--heading {
                @apply font-gibson pl-6 !important;

                &::after {
                    @apply text-amcWhite !important;
                }
            }
        }

        .mobile-quick-links {
            ul {
                @apply px-3 py-7 gap-3.5 text-sm flex flex-col hover:[&>li]:text-amcPrimaryBlue-hover !important;

                li:first-child {
                    @apply text-amcOffersRed;
                }
            }
        }

        .mobile-account-login {
            @apply bg-amcBlack border-t border-amcWhite sticky bottom-0;
        }

        .logged-in-user,
        .logged-out-user {
            @apply hover:text-amcPrimaryBlue justify-center px-3 py-4 !important;

            svg {
                @apply size-4 align-bottom ml-2 mt-0.5 fill-current !important;
            }
        }
    }

    body[data-customer-logged-in] {
        .logout-button a {
            @apply block pt-4 pb-12 !important;
        }

        a.logged-in-user {
            @apply flex !important;
        }
    }

    body[data-customer-logged-out] a.logged-out-user {
        @apply flex !important;
    }


    /* Functional Styles */
    .s48meganav {
        .nav.scroll-2 { transform: translateX(-100%) !important }
        .nav.scroll-3 { transform: translateX(-200%) !important }
        .nav.scroll-4 { transform: translateX(-300%) !important }
    
        .menu-block__depth-0--item.menu-visible {
            .menu-block__depth-1--item {
                & > .menu-block--anchor {
                    @apply hidden;
                }
    
                & > .menu-block--wrapper {
                    @apply static block right-0;
                }
            }
        }
    }
    
    .s48meganav .nav .menu-block__depth-0 .menu-block--item.menu-visible>.menu-block--wrapper>*>*>.menu-block--wrapper {
        @apply block;
    }
}

.pdp__imageGallery {
    .pdp__imageGallery__mainImage {
        @apply flex justify-center items-center aspect-square;
    }

    .pdp__imageGallery__secondaryImages {
        .pdp__imageGallery__secondaryImage {
            @apply flex justify-center items-center [&:nth-child(n+6)]:hidden aspect-square border border-amcLightGrey cursor-pointer;
        }
    }
}

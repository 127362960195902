@import "./product/imageGallery.css";
@import "./product/sntGallery.css";
@import "./product/feefo.css";
@import "./product/pdpBanner.css";

.productView {
    .pdp__addToBag__quantity .form-inlineMessage {
        @apply hidden;
    }
    .form-input--incrementTotal {
        -moz-appearance: textfield;

        &input::-webkit-outer-spin-button,
        &input::-webkit-inner-spin-button {
            @apply appearance-none;
        }
    }
}

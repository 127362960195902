.promotions-text {
    @apply text-center bg-amcOffWhite p-4;

    > * + * {
        @apply m-4;
    }

    h1, h2, h3, h4, h5 {
        @apply border-amcBlack text-2xl font-gin font-medium mb-4;
        
        + * {
            @apply mt-0;
        }
    }
    p {
        @apply text-base;
    }
    a {
        @apply text-amcPrimaryBlue underline hover:no-underline;
    }
    ul, ol {
        @apply pl-5;
    }
    ul {
        @apply list-disc;
    }
    ol {
        @apply list-decimal;
    }
}

.stockStatus {
    @apply flex items-center uppercase font-semibold whitespace-nowrap before:w-2.5 before:h-2.5 before:bg-current before:rounded-full before:mr-1;
    
    &.stockStatus--inStock {
        @apply text-amcFinanceGreen;
    }
    &.stockStatus--downloadable {
        @apply text-amcFinanceGreen;
    }
    &.stockStatus--preOrder {
        @apply text-amcEpicOrange;
    }
    &.stockStatus--outOfStock {
        @apply text-amcHighlightRed;
    }
}

/* Order Confirmation Mobile Order Summary */
.modal:not(.optimizedCheckout-cart-modal) {
    .cart-modal-header {
        @apply relative;

        .cart-modal-close {
            @apply absolute top-1/2 right-3.5 -translate-y-1/2 flex items-center justify-center size-14;

            .icon {
                @apply size-8;

                svg {
                    @apply fill-amcBlack;
                }
            }
        }
        .cart-modal-title {
            @apply block font-semibold text-left;
    
            .cart-heading-subheader {
                @apply hidden;
            }
        }
        .cart-modal-link {
            @apply hidden;
        }
    }
    .cart-modal-body {
        @apply p-0 h-auto !important;
    }
}

/* Checkout Mobile Order Summary */
.optimizedCheckout-cart-modal {
    .cart-modal-header {
        @apply relative;
        
        .cart-modal-link {
            @apply hidden;
        }
        .cart-modal-title {
            @apply text-left font-semibold;
    
            .cart-heading-subheader {
                @apply hidden;
            }
        }
        .cart-modal-close {
            @apply absolute top-1/2 right-3.5 -translate-y-1/2 size-14;

            .icon, .icon svg {
                @apply size-full;
            }

            svg {
                @apply fill-amcBlack;
                
                path:first-child {
                    @apply fill-none;
                }
            }
        }
    }
    .cart-modal-body {
        @apply p-0 h-auto !important;

        .productList {
            .productList-item {
                .product-actions {
                    @apply text-right;
                }
            }
        }
    }

    .modal-footer {
        @apply hidden;
    }
}

.cart.optimizedCheckout-orderSummary,
.cart-modal-header.optimizedCheckout-orderSummary,
.cart-modal-body.optimizedCheckout-orderSummary {
    @apply my-0 shadow-none border-none rounded-none;
    
    .cart-header {
        @apply p-4 border border-amcLightGrey;
    }

    .cart-header + .cart-section {
        @apply px-0;
    }

    .cart-header ~ .cart-section:nth-child(n+3) {
        @apply border-x;
    }

    .cart-section {
        @apply border-t-0 border-b p-5;

        .cart-priceItem-label {
            @apply font-semibold;
        }
        .cart-priceItem-value {
            @apply font-normal;
        }
        .cart-priceItem--total .cart-priceItem-value {
            @apply text-base font-semibold;
        }
    }

    .productList {
        .productList-item .product {
            @apply grid;
            grid-template-columns: auto 1fr;

            .product-figure {
                @apply row-span-2;
            }
            .product-body {
                @apply w-full;
                order: initial;
            }
            .product-actions {
                 @apply col-start-2 w-auto;
                 word-wrap: initial;
                 order: initial;
                 margin-left: initial;

                 .product-price {
                    @apply font-semibold;
                 }
            }
        }
    }

    .cart-priceItem {
        @apply mb-0.5;
    }

    [data-test="cart-subtotal"] {
        @apply mb-3;
    }
    [data-test="cart-discount"] {
        @apply p-5 pb-0 -mx-5 mt-5 mb-3 border-t border-amcLightGrey;
    }
    [data-test="cart-shipping"] {
        @apply mt-3;
    }

    [data-test="cart-coupon"], [data-test="cart-gift-certificate"] {
        .cart-priceItem {
            @apply text-amcHighlightRed;

            .cart-priceItem-label {
                @apply font-semibold;

                .cart-priceItem-link {
                    @apply hidden;
                }
            }
            .cart-priceItem-value {
                @apply whitespace-nowrap;
            }
            .cart-priceItem-postFix {
                @apply hidden;
            }
        }
    }
}

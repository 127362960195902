.amc-prose {
    > * + * {
        @apply mt-8;
    }

    h1, h2, h3, h4, h5 {
        @apply border-b border-amcBlack text-lg font-medium mb-4;

        + * {
            @apply mt-0;
        }
    }

    ul, ol {
        @apply pl-5;
    }
    ul {
        @apply list-disc;
    }
    ol {
        @apply list-decimal;
    }
}

@keyframes bcSpin {
  0% {
      transform: translateY(-50%) rotate(0deg)
  }

  100% {
      transform: translateY(-50%) rotate(360deg)
  }
}

.loadingOverlay::before,
.button.is-loading::before {
    animation: bcSpin 500ms infinite cubic-bezier(0.69, 0.31, 0.56, 0.83);
}

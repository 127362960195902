.optimizedCheckout-orderSummary.cartDrawer {
    @apply border border-amcDarkGrey rounded-none p-4;
    z-index: 150;

    .cartDrawer-figure {
        .cartDrawer-imageWrapper {
            @apply relative bg-amcWhite;
    
            img {
                @apply absolute top-1/2 left-1/2 max-h-full object-contain transform -translate-x-1/2 -translate-y-1/2;
            }
        }
    }
    .cartDrawer-body{
        .cartDrawer-items {
            @apply text-lg;
        }
    }
    .cartDrawer-actions {
        .cartDrawer-total {
            @apply text-xl font-semibold;
        }
    }
}

.btn {
    @apply flex items-center justify-center p-4 pt-3 w-full text-center uppercase font-gin text-2xl hover:cursor-pointer disabled:opacity-75 disabled:cursor-not-allowed;
}

.btn--gibson {
    @apply font-gibson text-base p-4 normal-case;

    &.btn--small {
        @apply p-3 text-base;
    }
}

.btn--extraSmall {
    @apply p-2 text-sm;
}

.btn--small {
    @apply p-2 text-lg;
}

.btn--black {
    @apply bg-amcBlack text-amcWhite hover:text-amcWhite focus:text-amcWhite disabled:bg-amcBlack hover:bg-amcDarkGrey;
}

.btn--blue {
    @apply bg-amcPrimaryBlue text-amcWhite hover:bg-amcPrimaryBlue-hover disabled:bg-amcPrimaryBlue hover:text-amcWhite focus:text-amcWhite;
}

.btn--green {
    @apply bg-amcFinanceGreen hover:bg-amcFinanceGreen-hover disabled:bg-amcFinanceGreen text-amcWhite hover:text-amcWhite focus:text-amcWhite;
}

.btn--white {
    @apply bg-amcWhite text-amcBlack border border-amcLightGrey hover:text-amcBlack focus:text-amcBlack hover:bg-amcOffWhite;

    &.btn--loading::after {
        @apply bg-[url('../icons/andertons/loading-spinner-dark.svg')] !important;
    }
}

.btn--loading {
    @apply relative text-transparent hover:text-transparent focus:text-transparent;
}
.btn--loading::after {
    @apply absolute w-5 h-5 animate-spin bg-[url('../icons/andertons/loading-spinner-light.svg')];
    content: "";
    inset: calc(50% - theme("spacing[2.5]"));
}

.background-spinner--dark {
    @apply animate-spin bg-no-repeat bg-center bg-[url('../icons/andertons/loading-spinner-dark.svg')];
}

.loading-overlay {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.15);
    display: none;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.loading-spinner {
    animation: spin 1s linear infinite;
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #476bef;
    height: 60px;
    pointer-events: none;
    width: 60px;
}

.no-scroll {
    overflow: hidden;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
